import { v4 as uuidv4 } from 'uuid';

export default function ({ $axios, $auth, store, redirect, route }) {


	let query = route.query.query;
	return new Promise(resolve => {
		if (query) {
			$auth.loginWith('local', {
				data: {
					uuid: uuidv4(),
					query: query,
				},
			})
				.then((responseLogin) => {
					store.dispatch('customer/fetchCustomer', responseLogin.data.payload[0])
						.then(() => {
							$auth.strategy.token.set(localStorage.getItem('bearerToken'));
							resolve();

							if (responseLogin.data.payload[0].customer.contractSigned) {
								redirect('/contract-complete-done?signed=true');
							}
							else {
								$axios.post('/contract/identifier', {
									id: responseLogin.data.payload[0].customer.customerId,
								})
									.then(response => {
										store.dispatch('customer/fetchIdentifier', response.data.payload.identifier);
									})
									.catch(function (error) {
										// console.log(error);
									});
								redirect('/dashboard');
							}
						});
				});
		}
		else {
			resolve();
		}
	});
}
