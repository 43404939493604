
export default {
	computed: {

	},

	watch: {
		$route () {
			// window.parent.postMessage({ task: 'scrollTop' }, this.targetOrigin);
		},
	},
};
